:root {
    --footer-bg-color: #222;
    --footer-text-color: #ccc;
    --text-blue-color: #5188ae;
    --text-orange-color: #be531c;
    --text-red-color: #dd3526;
    --button-background: #dd3526;
    // --text-red-hover-color: #cc1200;
    --text-red-hover-color: #e7573b;
    --text-red-dark-color: #bc1f18;
    --text-gray-color: #666;
    --text-gray: #808080;
    --text-dark-gray-color: #444;
    --text-light-black-color: #333;
    --btn-color: #bbb;
    --btn-text-color: #fff;
    --header-background: #f5f5f5;
    --divider-color: #555;
    --transform-bg: transparent;
    --tab-bg-color: #f5f5f5;
    --border-color: #ccc;
    --border-color-heading: #c6c6c6;
    --border-color-light: #e4e4e4;
    --bg-search-overlay: #fff;
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, 0.44);
    --search-criteria-search-term-color: #0a0a0a;
    --search-item-divider-color: #eee;
    --search-item-title-color: #000;
    --light-bg-color: #f5f5f5;
    --overlay-content-background: transparent;
    --overlay-content-bg: transparent;
    --overlay-background: #fff;
    --button-border-width: 2px;
    --button-border: rgba(0, 0, 0, 0.2);
    --button-padding: 0 12px;
    --primary-base-color: #dd3526;
    --primary-accent-color: #333;
    --popup-background: #fff;
    --popup-overlay: rgba(0, 0, 0, 0.5);
    --cart-wishlist-bg: #fff;
    --input-border-color: #bbb;
    --navigation-tabs-cart-height: calc(50px + env(safe-area-inset-bottom));
    --detete-color: #333;
    --header-height-desktop: 162px;
    --header-height-pdr: 130px;
    --header-height-mb: calc(90px + env(safe-area-inset-top));
    --header-height-hp-mb: calc(175px + env(safe-area-inset-top));
    --header-height-desktop-mg: 202px;
    --tree-border-color: #737373;
    --nav-tabs-height: env(safe-area-inset-bottom);
    --price-discount-color: #333;
    --payjustnow-bg-color: #14204a;
    --star-icon-color: #F6C707;
}
