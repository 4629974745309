/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.lp-imagegrid-container {
    margin-block-end: 30px;
    
    .lp-imagegrid-header {
        font-size: 24px;
        font-weight: bold;
        margin-block-end: 10px;
        text-align: center;
    }
    
    .lp-imagegrid-body {
        margin-block-end: 20px;
        text-align: center;
    }
    
    .lp-imagegrid-images {
        display: flex;
        flex-wrap: wrap;

        .adv-img {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 12px 15px;

            .title {
                text-align: center;
                font-size: 26px;
                font-family: Intro, sans-serif;
                line-height: 110%;
                -webkit-font-smoothing: antialiased;
                color: #535659;
                margin-block-start: 15px;
            }

            @media (max-width: 1164px) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @include mobile {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 8px;

                .title {
                    font-size: 18px;
                }
            }
        }
    }
}
