/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable block-closing-brace-empty-line-before */
/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable indentation */
/* stylelint-disable declaration-no-important */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable rule-empty-line-before */
//@a

.HomePage {
    .Image, .owl-carousel .owl-item img {
        height: auto;
    }
    .fr_slider2 {
        img {
            object-fit: cover;
        }
    }
    .slick-slide img {
        height: auto;
    }
    .slick-arrow {
        @include mobile {
            display: none !important;
        }
    }
    .fr_slider_top .slick-slide img {
        height: 500px;
        object-fit: cover;
        @include mobile {
            height: auto;
        }
    }
    .slick-dots {
        bottom: 10px;
        background: rgba(0, 0, 0, 0.2);
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 999px;
        li {
            margin: 0 2px;
            button::before {
                color: #fff;
                opacity: .8;
                font-size: 13px;
            }
        }
    }
    .slick-dots li.slick-active button::before {
        opacity: 1;
        color: #aaa;
    }
    .findGearPopup {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        &Wrapp {
            background: #fff;
            padding: 30px;
            .close {
                position: absolute;
                right: 10px;
                top: 10px;                            
                svg.CloseIcon {
                    width: 30px;
                    height: auto;
                    fill: #000;
                }
            }
            form.Form.Vehicle-Finder {
                display: block;
                padding: 0;
                margin-top: 35px;
                .VehicleFinder-Wrapper {
                    display: block;
                    max-width: 100%;
                    .Field {
                        max-width: 100%;
                        width: 100%;
                        margin-bottom: 0;
                        select {
                            margin-bottom: 20px;
                            border: 2px solid rgba(0, 0, 0, 0.2);
                            &:disabled {
                                display: none;
                            }
                        }
                    }
                }
                button {
                    width: 100%;
                    &[disabled] {
                        display: none;
                    }
                    .Button {
                        font-size: 17px;
                        line-height: 36px;                    
                    }
                }
            }
        }
        &Content {
            text-align: center;
            .h3 {
                font-family: 'regular-bold' , sans-serif;
                font-size: 20px;
                text-transform: uppercase;
                color: var(--text-red-color);
            }            
            h2 {
                font-size: 28px;
                line-height: 1.3;
                text-transform: uppercase;
                font-family: "Intro", sans-serif;
            }            
            .h4 {
                font-family: 'regular-book' , sans-serif;
                font-size: 18px;
                line-height: 1.3;
            }
        }
    }    
    .topStaticBanner {
        // background-image: url(../../images/homepage_Hero_Mobile.jpg);
        img {
            object-fit: cover;
            height: 500px;
            display: block;
            line-height: 0;
            @include mobile {
                height: 380px;
            }
        }
        &Wrap {
            position: absolute;
            bottom: 13%;
            left: 7%;
            color: #fff;
            z-index: 2;
            @include mobile {
                bottom: 0;
                left: 0;
                padding: 0 15px 15px 15px;
            }
            .review_rating {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                svg {
                    font-size: 18px;
                    margin-right: 3px;
                }
                span {
                    font-family: 'regular-bold' , sans-serif;
                    font-size: 22px;
                    text-transform: uppercase;
                    margin-left: 8px;
                }
                @include mobile {
                    display: block;
                    span {
                        display: block;
                        margin: 8px 0 0;
                        font-size: 18px;
                    }
                }
            } 
            
            h1 {
                color: #fff;
                font-size: 46px;
                text-transform: uppercase;
                font-family: "Intro", sans-serif;
                line-height: 1.1;
                max-width: 680px;
                @include mobile {
                    font-size: 28px;
                }
            }  

            &-Actions {
                display: flex;
                gap: 10px;
                flex-direction: row;

                .btn {
                    line-height: 38px;
                    padding: 0 20px;
                    font-size: 18px;
                    margin-top: 15px;
                    @include mobile {
                        line-height: 34px;
                        padding: 0 20px;
                        font-size: 16px;
                        width: auto;
                    }
                }

                .btn-primary-outline {
                    margin-top: 15px;
                    border-color: #fff;
                    color: #fff;
                    background-color: transparent;
                    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);

                    &:hover {
                        background-color: #222;
                        border-color: #222;
                    }
                }

                @include mobile {
                    flex-direction: column;
                    gap: 0;
                }
            } 
            
            &Promo {
                h1 {
                    font-size: 36px;

                    @include mobile {
                        font-size: 28px;
                    }
                }

                &SubText {
                    font-size: 22px;
                    font-family: 'regular-bold', sans-serif;
                    margin-bottom: 25px;
                    margin-top: 15px;
                    text-transform: uppercase;

                    @include mobile {
                        font-size: 18px;
                    }
                }
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: rgba(0,0,0,0.2);
        }
        &Promo {
            // line-height: 0;

            img {
                height: 500px;
                object-fit: cover;
                @include mobile {
                    height: auto;
                }
            }
        }
    }
    .topStatic {
        &PromoText {
            background-color: #fff;
            text-transform: uppercase;
            padding: 20px 0;
            border-bottom: 1px solid var(--primary-divider-color);

            &Header {
                font-size: 36px;
                text-align: center;
                font-family: "Intro", sans-serif;
                color: var(--text-red-color);
                margin: 10px 0;

                @include mobile {
                    font-size: 26px;
                }
            }

            &Subheader {
                font-size: 28px;
                text-align: center;
                font-family: "regular-bold", sans-serif;
                color: var(--text-dark-color);

                @include mobile {
                    font-size: 20px;
                }
            }

            &Button {
                text-align: center;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
    .staticBanner {
        &Second {
            h2 {
                text-align: center;
                text-transform: uppercase;
                font-size: 32px;
                line-height: 1.1;
                margin: 30px 15px;

                @include mobile {
                    font-size: 26px;
                }
            }
        }
        &Wrap {
            display: flex;
            justify-content: center;
            gap: 4px;

            h3 {
                text-align: center;
                position: absolute;
                left: 25px;
                top: 25px;
                color: #fff;
                font-size: 32px;
                text-transform: uppercase;
                font-family: "Intro", sans-serif;
                margin: 0;
                z-index: 2;     
                text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);  

                @include mobile {
                    font-size: 26px;
                }     
            }
            img {
                height: auto;
                display: block;
                transition: transform 0.5s ease;
            }
            @include mobile {
                flex-direction: column;
            }
        }
        &Item {
            overflow: hidden;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                background: rgba(0,0,0,.2);
            }
            &:hover {
                img {
                    transform: scale(1.2);
                    @include mobile {
                        transform: none;
                    }
                }
            }
            @include mobile {
                border: none;
                &:nth-child(2) {
                    margin: 8px 0;             
                }
            }
        }
        &ItemContent {
            position: absolute;
            bottom: 25px;
            left: 25px;
            right: 25px;
            color: #fff;
            display: flex;
            align-items: center;
            z-index: 2;
            .subTitle {
                font-family: 'regular-bold' , sans-serif;
                font-size: 20px;
                text-transform: uppercase;
                padding-right: 20px;
                text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
                flex: 1;
            }
            .btn-primary {
                line-height: 38px;
                font-size: 18px;
                min-width: 120px;
                margin-left: auto;
                white-space: nowrap;
            }  
        }
    }
    #brandVideo, 
    #pickUpWhereLeftOff {
        h2 {
            text-transform: uppercase;
            font-size: 32px;
            text-align: center;
            margin: 60px 0 30px;

            @include mobile {
                font-size: 26px;
                margin: 40px 0 20px;
            }
        }

        .owl-dots {
            display: none;

            @include mobile {
                display: flex;
                bottom: -25px;
            }
        }
    }
    .staticCategories {
        width: 32.3%;
        margin-bottom: 20px;
        overflow: hidden;
        img {
            height: auto;
            transition: transform 0.5s ease;
        }
        h3 {
            font-size: 28px;
            color: #fff;
            position: absolute;
            margin: 0;
            bottom: 20px;
            left: 20px;
            z-index: 1;
            font-family: "intro", sans-serif;
            text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);

            @media (max-width: 1199px) {
                font-size: 26px;
                text-shadow: none;
            }
        }
        &Wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .staLink {
                line-height: 0;
                display: block;
                position: relative; /* To position the overlay correctly */
                
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    background: rgba(0, 0, 0, 0.2);
                    @include mobile {
                        display: none;
                    }
                }

                &:hover img {
                    transform: scale(1.2);
                    @include mobile {
                        transform: none;
                    }
                }
            }
        }
        &Sec {
            margin-bottom: 30px;
            h2 {
                text-transform: uppercase;
                font-size: 32px;
                text-align: center;
                margin: 60px 0 30px;

                @include mobile {
                    font-size: 26px;
                }
            }
        }
        @include mobile {
            width: 47.5%;
            h3 {
                position: static;
                color: #000;
                font-size: 16px;
                text-align: center;
                margin-top: 10px;
                font-family: regular-bold, sans-serif;
            }
            &Sec {
                h2 {
                    text-align: center;
                    font-size: 32px;
                    line-height: 1.2;
                    margin: 40px 0 20px;

                    @include mobile {
                        font-size: 26px;
                    }
                }
            }
            .staLink::before {
                display: none;
            }
        }
    }
    .vehicleTestFitmentsBlocks {
        position: relative;
        line-height: 0;
        margin-bottom: 50px;
        margin-top: 50px;
        @include mobile {
            margin-bottom: 20px;
            margin-top: 40px;
        }

        h2 {
            text-transform: uppercase;
            font-size: 32px;
            text-align: center;
            @include mobile {
                font-size: 26px;
            }
        }
        &Content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 10px;
            left: 25px;
            right: 25px;
            z-index: 2;
            gap: 20px;
            h3 {
                color: #fff;
                text-transform: uppercase;
                font-size: 28px;
                text-shadow: 1px 3px 10px rgba(0, 0, 0, 0.9);
                text-align: center;
            }
            @include mobile {
                bottom: auto;
                top: 5px;
                flex-direction: column;
                gap: 8px;
                h3 {
                    font-size: 24px;
                }
            }
        }
        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        // Overlay styles
        &Container::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: rgba(0, 0, 0, 0.2);
        }
    }
    &-Content {
        .m-value-proposition {
            text-align: center;
            font-size: 11px;
            font-family: "regular-bold", sans-serif;
            text-transform: uppercase;
            color: #fff;
            background: var(--text-red-color);
            padding: 8px 5px;
            line-height: 1.42;
        }
        .fr_slider_top {
            @include desktop {
                min-height: 500px;
                background-color: #ececec;
                .owl-item img {
                    object-fit: cover;
                    height: 500px;
                }
            }
            @include mobile {
                .owl-nav {
                    display: none;
                }
            }

            .Vehicle-Finder {
                flex-direction: column;
                max-width: 350px;
                gap: 10px 10px;
                padding: 0;
                .VehicleFinder-Wrapper {
                    flex-direction: column;
                    max-width: 100%;
                    gap: 10px 10px;
                    .Field {
                        width: 100%;
                        max-width: 100%;
                    }
                }
                @include mobile {
                    display: none;
                }
            }
        }
        .fr_banner_carousel_initial {
            .item {
                // Div block will pretend to be an image during loading
                display: block;
                width: 500px;
                height: 300px;
                background-color: #ececec;
                &::before {
                    box-shadow: none !important;
                }
            }
        }
        .content {
            position: absolute;
            z-index: 9;
            max-width: 800px;
            margin: 30px auto 0;
            left: 0;
            right: 0;
            @include mobile {
                margin: 0 auto;
            }
            .fr_slider_content_top {
                font-family: regular-bold;
                font-size: 22px;
            }
            .fr_slider_content_bottom {
                font-size: 40px;
                font-family: intro, sans-serif;
                line-height: 110%;
                margin-top: 10px;
                @include mobile {
                    zoom: 0.62;
                }
            }
            .Homepage-Link {
                margin-top: 20px;
                &:empty {
                    display: none;
                }
                &.Video svg {
                    margin-right: 5px;
                    top: 1px;
                }
                @include mobile {
                    margin-top: 20px;
                }
            }
        }
        .top {
            top: 30px;
        }
        .bottom {
            bottom: 40px;
        }
        .left {
            left: 10px;
            right: 10px;
            @include desktop {
                left: 20px;
            }
        }
        .right {
            right: 10px;
            text-align: right;
            @include desktop {
                right: 20px;
            }
        }
        .fr_banner_hero {
            background-color: #222;
            padding: 20px;
            display: block;
            width: 100%;
            @include desktop {
                padding: 30px 20px;
            }
            
            .fr_banner_title {
                font-size: 22px;
                color: #fff;
                font-family: "intro", sans-serif;
                text-align: center;
                font-weight: 400;
                @include desktop {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 30px;
                }
                span {
                    display: block;
                }
                .Homepage-Link {
                    width: 100%;
                    font-size: 16px;
                    margin-top: 10px;
                    @include desktop {
                        width: auto;
                        margin: 0 0 0 20px;
                        white-space: nowrap;
                    }
                    @include mobile {
                        transition: none
                    }
                }
            }
            .fr_banner_sub_title {
                margin-top: 4px;
                margin-bottom: 4px;
                font-size: 16px;
                color: #f1f1f1;
                font-family: "regular-book", sans-serif;
                text-align: center;
                font-weight: 300;
                text-transform: uppercase;
                @include desktop {
                    font-size: 22px;
                }
            }
            .fr_banner_terms_text {
                margin-top: 8px;
                margin-bottom: 4px;
                font-size: 14px;
                color: #f9f9f9;
                font-family: "regular-book", sans-serif;
                text-align: center;
                font-weight: 300;
            
                @include desktop {
                    font-size: 16px;
                }
            }
        }
        .fr_banner_item.item {
            position: static;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                box-shadow: inset 0 0 150px 70px rgba(0, 0, 0, 0.6);
            }
            .title {
                font-size: 25px;
                font-family: "Intro", sans-serif !important;
                color: #fff;
                text-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
                margin: 0;
                line-height: 30px;
                text-align: left;
                position: absolute;
                top: 0;
                z-index: 2;
                padding: 20px 30px;
                max-width: 400px;
                font-weight: 400;
            }
            .fr_banner_content_bottom {
                display: flex;
                align-items: center;
                bottom: 25px;
                right: 0;
                z-index: 2;
                position: absolute;
                width: 100%;
                color: #fff;
                padding: 20px;
                box-sizing: border-box;
                .subtitle {
                    flex: 1;
                    color: #fff;
                    margin: 0 20px;
                    font-size: 17px;
                    line-height: 1.3;
                    max-width: 400px;
                    float: right;
                    text-align: right;
                    font-family: regular-bold, sans-serif;
                }
                .price {
                    display: none;
                }
            }
        }
        //.home_page_before_footer_section {
        .social_block_usa {
            background: url(../../images/dune_bg.jpg);
            background-size: cover;
            background-position: 50% 100%;
            display: inline-block;
            width: 100%;
            padding: 0 20px;
            padding-bottom: 200px;
            @include desktop {
                padding-left: 0;
                padding-right: 0;
            }
            .social_block_top {
                border-top: 1px solid #ccc;
                margin-top: 40px;
                padding-top: 20px;
                @include desktop {
                    display: flex;
                    > div {
                        width: 50%;
                        &:first-child {
                            padding-right: 15px;
                        }
                        &:last-child {
                            padding-left: 15px;
                        }
                    }
                }
                .icons {
                    display: flex;
                    .fab {
                        padding: 0 5px;
                        font-size: 28px;
                        color: rgba(255, 255, 255, 0.8);
                        margin-right: 20px;
                        margin-top: 3px;
                        // &.youtube {
                        //     order: 1;
                        // }
                        // &.instagram {
                        //     order: 2;
                        // }
                        // &.facebook {
                        //     order: 3;
                        // }
                        // &.pinterest-p {
                        //     order: 4;
                        // }
                        // &.twitter {
                        //     order: 5;
                        // }
                        // &.tumblr {
                        //     order: 6;
                        // }
                        a {
                            color: inherit;
                        }
                        svg.fa-tumblr {
                            font-size: 24px;
                        }
                        @include mobile {
                            font-size: 25px;
                            margin-right: 12px;
                            svg.fa-tumblr {
                                font-size: 21px;
                            }
                        }
                        &:hover {
                            color: #222;
                        }
                    }
                }
                .subHeading {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: 400;
                    margin-bottom: 5px;
                    font-family: regular-bold, sans-serif;
                }
            }
            h2 {
                font-size: 24px;
                line-height: 27px;
                margin-bottom: 10px;
                font-family: "intro", sans-serif;
                color: #fff;
                margin-top: 0;
                font-weight: 400;
                @include desktop {
                    font-size: 30px;
                    line-height: 1;
                }
            }
            .instafeed {
                margin: 30px 0 20px;
                border-bottom: 1px solid #ccc;
                padding-bottom: 30px;
                display: flex;
                flex-wrap: wrap;
                .col {
                    flex: 0 0 50%;
                    max-width: 50%;
                    .Image {
                        padding-bottom: 0 !important;
                        vertical-align: top;
                        .Image-Image {
                            position: static;
                            display: block;
                            object-fit: cover;
                            height: 155px !important;
                        }
                    }
                }
            }
            .social_newsletter_cont {
                @include desktop {
                    display: flex;
                    > div {
                        width: 50%;
                        &:first-child {
                            padding-right: 15px;
                        }
                        &:last-child {
                            padding-left: 15px;
                        }
                        a.MenuOverlay-Link {
                            display: none;
                        }
                    }
                }
                button {
                    width: auto;
                    line-height: 38px;
                    padding: 0 12px;
                    border-radius: 0 999px 999px 0;
                }
                form.Form.EmailFriend {
                    display: flex;
                    align-items: center;
                    .Field {
                        flex: 1;
                        margin: 0;
                    }
                }
                input {
                    height: 42px;
                    width: 100%;
                    border: 2px solid #ccc;
                    border-right: 0;
                    padding: 0 15px 0;
                    font-size: 15px;
                    color: #555555;
                    border-radius: 999px 0 0 999px;
                }
                p.Field-Message {
                    margin-bottom: 0;
                }
            }
        }
    }
    .btn-primary_video_play {
        background: var(--text-red-color);
        .VideoThumbnail-PlayIcon {
            border: none;
        }
        .Image {
            display: none;
        }
        * {
            position: static;
            font-size: 0;
        }
        button.VideoThumbnail-Button {
            border: none;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
        }
    }
    .Homepage-Link {
        border-radius: 999px;
    }
    // + .Breadcrumbs {
    //     display: none;
    // }
    .owl-theme {
        .owl-dots {
            //margin: 8px 0 5px 0;
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 10px;
            width: auto;
            background: rgba(0, 0, 0, 0.2);
            left: 50%;
            transform: translateX(-50%);
            border-radius: 999px;
            padding: 3px;

            .owl-dot span {
                background: rgba(255, 255, 255, 0.4);
            }
            .owl-dot.active span {
                background: #ffffff;
            }
            .owl-dot:hover {
                span {
                    background: #ffffff;
                }
            }

            @include desktop {
                bottom: 10px;
                padding: 4px;
            }
        }
    }

    .yotpo-loyalty-banner {
        position: relative;
        width: 100%;
        overflow: hidden;

        picture {
            display: block;
            width: 100%;
            height: 170px;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-content {
            position: absolute;
            top: 50%;
            left: 25%;
            transform: translate(-25%, -50%);
            text-align: center;
            color: #ffffff;
            width: 80%;
            max-width: 600px;
        }

        &-text {
            h2 {
                font-size: 32px;
                margin-bottom: 10px;
                color: #ffffff;
                margin-top: 0;
                text-transform: uppercase;
            }

            p {
                font-size: 1.2rem;
                line-height: 1.5;
                color: #ffffff;
            }
        }

        @include mobile {
            &-content {
                top: 0;
                transform: translate(-25%, 15%);
                width: 100%;
                margin: 0 auto;
            }

            &-text {
                h2 {
                    font-size: 2rem;
                }

                p {
                    font-size: 1rem;
                }
            }

            picture {
                height: 350px;
            }
        }
    }

    .cms-banner {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-top: 50px;

        picture {
            display: block;
            width: 100%;
            height: 170px;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-content {
            position: absolute;
            top: 50%;
            left: 25%;
            transform: translate(-25%, -50%);
            text-align: center;
            color: #ffffff;
            width: 80%;
            max-width: 600px;
        }

        &-text {
            h2 {
                font-size: 32px;
                margin-bottom: 10px;
                color: #ffffff;
                margin-top: 0;
                text-transform: uppercase;
                text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
            }

            p {
                font-size: 1.2rem;
                line-height: 1.5;
                color: #ffffff;
                margin: 0;
                text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
            }
        }

        .btn {
            margin-top: 10px;
        }

        @include mobile {
            &-content {
                top: 0;
                transform: translate(-25%, 15%);
                width: 100%;
                margin: 0 auto;
            }

            &-text {
                h2 {
                    font-size: 2rem;
                }

                p {
                    font-size: 1rem;
                }
            }

            picture {
                height: 350px;
            }
        }
    }

    #PromoCountdown-Section {
        text-align: center;
        color: #ffffff;
        padding: 40px 0;
        // background: #12100b url('https://www.frontrunneroutfitters.com/media/landing-pages/home/bf-2024-countdown-banner.jpg') no-repeat center center;
        background: #fff;
        background-size: cover;

        @include mobile {
            // background: #12100b url('https://www.frontrunneroutfitters.com/media/landing-pages/home/bf-2024-countdown-banner-mobile.jpg') no-repeat center center;
            background-size: cover;
        }

        h2 {
            font-size: 2.5em;
            margin: 0 0 20px 0;
            color: #000;
            text-transform: uppercase;
            font-family: "intro", sans-serif;
        }

        .LargeCountdown-Block {
            font-family: Arial, sans-serif;

            .large-countdown-timer {
                display: flex;
                justify-content: center;
                gap: 15px;

                .time-box {
                    text-align: center;

                    .time {
                        font-size: 2.5em;
                        font-weight: bold;
                        display: flex;
                        flex-direction: row;
                        gap: 5px;

                        span {
                            // background-color: var(--text-red-color);
                            background-color: #5188AE;
                            padding: 10px;
                            border-radius: 0;
                            min-width: 40px;
                        }
                    }

                    .label {
                        margin-top: 10px;
                        font-size: 18px;
                        text-transform: uppercase;
                        font-family: "regular-bold", sans-serif;
                        font-weight: 600;
                        color: #000;
                    }
                }

                .separator {
                    font-size: 2.5em;
                    font-weight: bold;
                    margin-top: 8px;
                }
            }
        }

        @include mobile {
            padding: 50px 0;

            h2 {
                font-size: 2rem;
            }

            .LargeCountdown-Block {
                .large-countdown-timer {
                    gap: 4px;

                    .time-box {
                        .time {
                            font-size: 2rem;

                            span {
                                padding: 8px 5px;
                                min-width: 30px;
                                font-size: 1.5rem;
                            }
                        }

                        .label {
                            margin-top: 8px;
                            font-size: 1rem;
                        }
                    }

                    .separator {
                        font-size: 1.5rem;
                        margin-top: 6px;
                    }
                }
            }
        }
    }
}

.VideosPopup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(35, 35, 35, 0.7);
    z-index: 999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &Wrapp {
        overflow: hidden;
        position: relative;
        width: 82%;
        max-width: 1320px;
        margin: 0 auto;
        max-height: 100%;
        @include mobile {
            width: 96%;
        }
        &::after {
            padding-top: 56.25%;
            display: block;
            content: "";
        }
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .close {
            background: url("../../images/fancybox_sprite.png");
            z-index: 999;
            right: 0;
            top: 0;
            position: absolute;
            width: 36px;
            height: 36px;
            font-size: 0;
        }
    }
}

.text-shadow {
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.9);
}

// home page b2b
.homepageB2B, .homepageB2C {
    @media (max-width: 991px) {
        &.container {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .container {
        @include mobile {
            padding: 0 15px;
        }
    }
    .ProCatName {
        background: #F5F5F5;
        border: 1px solid #D9D9D9;
        border-radius: 100px;
        display: inline-block;
        padding: 11px 15px;
        color: #313336;
        margin-right: 10px;
        font-size: 16px;
        text-transform: uppercase;
        font-family: regular-book, sans-serif;
        margin-bottom: 10px;
        &:hover {
            background: #444;
            color: #fff;
        }
    }
    .VehicleFinder {
        &Section {
            margin-top: 40px;
            &.vehicleHide {
                display: none;
            }
            .Vehicle-Finder {
                button {
                    // display: none;
                    &.ShowProducts {
                        display: inline-block;
                        width: auto;
                    }
                }
            }
        }
        &Img {
            background-image: url(../../images/vehicle_img.png);
            background-repeat: no-repeat;
            width: 248px;
            min-width: 248px;
            height: 210px;
            @include mobile {
                width: 100%;
                background-size: cover;
            }
        }
        &Left {
            background: #F5F5F5;
            border: 1px solid #D9D9D9;
            border-radius: 4px;
            padding: 12px;
            display: flex;
            @include mobile {
                display: block;
            }
        }
        &Wrap {
            padding-left: 20px;
            max-width: 630px;
            width: 100%;
            @include mobile {
               padding: 0 10px;
            }
            form.Form.Vehicle-Finder {
                padding: 0;
                display: block;
                .VehicleFinder-Wrapper {
                    flex-wrap: wrap;
                    max-width: 100%;
                    .Field-Wrapper {
                        width: 50%;
                        max-width: 50%;
                        margin: 0;
                        padding-right: 18px;
                        margin-bottom: 15px;
                        @include mobile {
                            width: 100%;
                            max-width: 100%;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    .mg_30 {
        margin-bottom: 55px;
    }
    h3 {
        font-family: "regular-bold", sans-serif;
        text-transform: uppercase;
        font-size: 28px;
        line-height: 1;
        color: #313336;
    }
    .product-slider {
        display: flex;
    }    
    .StaticBlock {
        &s {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
        }
        @include mobile {
            width: 100%;
            margin-bottom: 20px;
            &s {
                display: block;
            }
        }
        h3 {
            margin: 0 0 25px;
        }
        padding: 18px 16px;
        background: #F5F5F5;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        width: 32.5%;
    }
    .btn-2 {
        font-family: "regular-bold", sans-serif;
        font-size: 14px;
        border: 1px solid #999;
        background-color: #fff;
        border-radius: 100px;
        padding: 7px 12px;
        text-transform: uppercase;
        color: #666;
        margin-right: 15px;
        display: inline-block;
        line-height: 1.1;
        &:hover {
            border-color: #222;
            background-color: #222;
            color: #fff;
        }
    }
    .My {
        &Order {
            &Details {
                margin-top: 30px;
                .title {
                    font-weight: 600;
                    margin-bottom: 10px;
                    font-size: 16px;
                }
                .text {
                    font-size: 15px;
                    margin-bottom: 6px;
                }
            }
            &Top {
                display: flex;
                align-items: center;
                h3 {
                    margin-right: 10px;
                }
                @include mobile {
                    display: block;
                    margin-bottom: 30px;
                }
            }
        }
        &Company {
            min-width: 380px;
            margin-right: 20px;
            @media (max-width: 991px) {
                margin-right: 0;
            }
            @include mobile {
                min-width: unset;
            }
            &Oder {
                display: flex;
                .RecentOrdersBox {
                    background: #F5F5F5;
                    border: 1px solid #D9D9D9;
                    border-radius: 4px;
                    padding: 14px;
                    .MyAccountMyOrders {
                        border: 1px solid #D9D9D9;
                        margin-bottom: 17px;
                    }
                    .MyAccountMyOrders-Table, thead th {
                        background-color: #fff;
                        margin: 0;
                    }
                    thead th {
                        border-bottom: 1px solid #D9D9D9;
                    }
                }
                @media (max-width: 991px) {
                    display: block;
                }
            }
            &Box {
                background: #F5F5F5;
                border: 1px solid #D9D9D9;
                border-radius: 4px;
                padding: 14px;
                &Inner {
                    margin-bottom: 33px;
                    color: #313336;
                }
                span {
                    margin-right: 5px;
                }
            }
        }    
    }

    .latestGearzetteArticles {
        // display: flex;
        // justify-content: space-between;
        /* stylelint-disable-next-line no-duplicate-selectors */
        &Block {
            margin-top: 40px;

            h3 {
                display: flex;
                flex-direction: row;
                align-items: center;
                .btn-2 {
                    margin-left: auto;
                }

                @include mobile {
                    text-align: center;
                    flex-direction: column;
                    .btn-2 {
                        margin-left: unset;
                        margin-top: 10px;
                    }
                }
            }
        }
        .slick-slide {
            padding: 0 9px;
        }
        .PostItem {
            .btn {
                position: absolute;
                bottom: 15px;
                right: 18px;
            }
        }
        .fr-post-list-content {
            font-family: "intro", sans-serif;
            color: #fff;
            position: absolute;
            top: 20px;
            left: 20px;
            right: 20px;
            h4 {
                font-size: 24px;
                color: #fff;
            }
            
        }
        .fr-post-list-featured-image {
            border-radius: 10px;
            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 10px;
            }
            img {
                border-radius: 10px;
                display: block;
                height: 300px;
                object-fit: cover;
            }        
        }
        
    }
}

.homepageB2B {
    h3 {
        margin: 18px 0;
    }
    .fr_banner_hero {
        background-color: #222;
        .fr_banner_title {
            color: #fff;
        }
        .fr_banner_sub_title {
            color: #ececec;
        }
        .fr_banner_terms_text {
            color: #ababab;
        }
    }

    #pickUpWhereLeftOff {
        h2 {
            text-transform: uppercase;
            font-family: "regular-bold", sans-serif;
            text-align: left;
            font-size: 28px;
            line-height: 1;
            color: #313336;
            margin: 60px 0 15px;

            @include mobile {
                font-size: 26px;
                margin: 40px 0 20px;
            }
        }
    }
}
