/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable declaration-no-important */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MenuOverlay-Link {
    color: inherit;
}

main.MenuPage {
    margin-top: 0 !important;
    z-index: 999;
}

.Overlay.MenuOverlay {
    background: #272727;
    color: #fff;
    display: flex;
    flex-direction: column;

    @include desktop {
        position: fixed;
        top: var(--header-height);
        left: 0;
        width: 100%;
        height: calc(100% - var(--header-height));
        overflow: scroll;
        overflow-x: hidden;
    }

    a {
        color: inherit;

        &:hover {
            text-decoration: none;
        }
    }

    .outlet-badge-container {
        display: flex;
        align-items: center;
        gap: 10px;

        .new-badge {
            background: var(--text-blue-color);
            padding: 0 7px;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            border-radius: 5px;
            color: #fff;
        }
    }

    @include mobile {
        &> div {
            &:nth-child(1) { order: 1; }

            &:nth-child(2) { order: 2; }

            &:nth-child(3) { order: 3; }

            &:nth-child(4) { order: 4; }

            &:nth-child(5) { order: 5; }

            &:nth-child(6) { order: 6; }

            &:nth-child(7) { order: 7; }

            &:nth-child(8) { order: 8; }

            &:nth-child(9) { order: 9; }

            &:nth-child(10) { order: 10; }

            &:nth-child(11) { order: 11; }

            &:nth-child(12) { order: 12; }

            &:nth-child(13) { order: 13; }

            &:nth-child(14) { order: 14; }

            &:nth-child(15) { order: 15; }

            &.whats_new {
                order: 9;

                &.Sn {
                    order: 8;
                }
            }
        }
    }
}

// .Overlay.Overlay_isVisible {
//     &>.accordion {
//         &:nth-child(3)>.accordion__item>.accordion__heading {
//             border-left: 5px solid var(--text-blue-color);
//         }

//         &:nth-child(4)>.accordion__item>.accordion__heading {
//             border-left: 5px solid var(--text-orange-color);
//         }

//         &:nth-child(6)>.accordion__item>.accordion__heading {
//             border-left: 5px solid #777;
//         }
//     }

//     .menu_whats_new {
//         border-left: 5px solid var(--text-red-color);
//     }

//     .menu_deals {
//         border-left: 5px solid #fff;
//     }
// }

.menu_deals {
    .deals-badge-container {
        position: relative;

        .deals-badge {
            display: inline-block;
            position: relative;
            margin-left: 10px;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #fff;
            background: var(--text-blue-color);
            padding: 0 7px;
            // animation: blinker 1.5s linear infinite;
        }
    }
}
