/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable number-max-precision */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

//  div div.Loader {
//     position: fixed;
//  }

.AdventurePage {
    min-height: 500px;

    .container {
        width: 100%;

        @media (min-width: 1200px) {
            max-width: 1170px;
        }

        @media (min-width: 1600px) {
            max-width: 1405px;
        }

        @include mobile {
            margin-inline: auto;
            max-width: 460px;
            padding-inline: 15px;

            .lp-product-slider {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    h1 {
        display: none;
    }

    .slider {
        min-height: 500px;

        @include mobile {
            min-height: auto;
        }

        .owl-dots {
            margin-block: 10px 7px;
        }
    }

    .lp-product-slider {
        padding: 60px 30px;

        .CmsProductSlider {
            padding: 0;
        }

        &-text {
            max-width: 1170px;
            width: 100%;
            margin: 0 auto;

            &-header {
                font-size: 32px;
                font-family: Intro, sans-serif;
                line-height: 110%;
                margin-top: 0px;
                margin-bottom: 10px;
                color: #000;
                text-transform: uppercase;
                text-align: left;
        
                @include mobile {
                    font-size: 26px;
                    text-align: center;
                }
            }
        
            &-body {
                font-size: 18px;
                font-family: regular-bold, sans-serif;
                line-height: 110%;
                margin-bottom: 15px;
                color: #484848;
                text-transform: uppercase;
                text-align: left;
        
                @include mobile {
                    font-size: 16px;
                }
            }
        }

        .owl-carousel .owl-nav.disabled {
            display: block;

            @include mobile {
                display: none;
            }
        }

        @media(max-width: 1200px) {
            margin: 0 25px;
        }

        @include mobile {
            margin: 0;
            padding: 20px 15px;
        }
    }

    .fr_slider_top {
        .fr_slider_content_bottom {
            font-size: 40px;
            font-family: "Intro", sans-serif;
            line-height: 110%;
            margin-block-start: 10px;

            @include mobile {
                font-size: 26px;
            }
        }

        .fr_slider_content_top {
            font-family: regular-bold, sans-serif;
            font-size: 18px;
        }

        .content {
            position: absolute;
            max-width: 900px;
            margin: 0 auto;
            z-index: 1;
            inset-block-start: 50px;
            inset-inline: 0;
            padding: 0 20px;

            &.bottom {
                inset-block: auto 50px;
            }
        }

        .link {
            margin-block-start: 50px;
        }

        img {
            object-fit: cover;
            height: 500px;

            @include mobile {
                height: 280px;
            }
        }
    }

    .product-slider {
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;

        &.product-grid {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: center;

            .child {
                width: 100%;
                max-width: 100%;
                flex: 0 1 calc(20% - 8px);

                @include mobile {
                    flex: 0 1 calc(50% - 10px);
                    width: 100%;
                    max-width: 100%;
                    min-width: 165px;
                }
            }
        }
        // @include mobile {
        //     padding: 30px 15px;
        // }
    }

    .CmsProductSlider {
        .child {
            min-width: 200px;
            max-width: 200px;
            padding: 6px;
            margin-block-end: 0;

            @media (max-width: 580px) {
                min-width: 190px;
                max-width: 190px;
                padding: 4px;
            }
        }

        .owl-dots {
            margin-block-start: 10px;
        }

        .review_rating {
            margin-top: 5px;
        }
    }

    li.ProductCard {
        .pro_inner_bot {
            margin: 10px 0 2px;
        }

        .ProductCard-Name {
            font-size: 16px;

            @include mobile {
                font-size: 14px;
            }

            &:hover {
                color: var(--text-red-color);
            }

            .more_info {
                margin-block-start: 5px;
            }
        }

        .ProductCard .pro-inner-wrap {
            margin-block-end: 5px;
        }

        .ProductCard-PriceWrapper {
            margin-block-end: 8px;

            &_isEmpty {
                margin-block-end: 0;
            }
        }

        .Button.AddToCart {
            width: auto;
            min-width: unset;
            font-size: 15px;
        }

        .ProductCard-Stock.outofstock {
            width: auto;
            display: inline-block;
            padding: 0 10px;
            line-height: 30px;
        }
    }

    .adv-img-block {
        display: flex;
        flex-wrap: wrap;

        .adv-img {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;

            &:first-child {
                flex: 0 0 100%;
                max-width: 100%;

                @include mobile {
                    img {
                        height: 300px;
                        object-fit: cover;
                    }
                }
            }

            @include mobile {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
    }

    .lp-imagegrid-container {
        padding: 50px 0;

        .lp-imagegrid-content {
            text-align: center;
            padding: 0 10px;

            .lp-imagegrid-header {
                font-size: 56px;
                font-family: Intro, sans-serif;
                line-height: 110%;
                margin-block-end: 15px;
                color: #484848;

                @include mobile {
                    font-size: 30px;
                }
            }

            .lp-imagegrid-body {
                font-size: 18px;
                font-family: "Regular-Book", sans-serif;
                color: #585858;
                line-height: 150%;
                -webkit-font-smoothing: antialiased;
                margin-block-end: 25px;
            }
        }
    }

    .adv_top_slider img {
        object-fit: cover;
        height: 500px;
    }

    .fr_slider_content_layout {
        position: absolute;
        max-width: 800px;
        margin: 0 auto;
        z-index: 1;
        inset-block-start: 0;
        inset-inline: 0;

        &.fr_position_y_top {
            margin: 50px auto 0 auto;
        }
    }

    .text-shadow {
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }

    .fr_slider_content_container {
        text-align: end;
    }

    .fr_slider_bottom_text {
        font-size: 40px;
        font-family: "Intro", sans-serif;
        line-height: 110%;
        margin-block-start: 10px;
        color: #fff;
    }

    .lp-overlay-layout {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        position: absolute;
        inset-block-start: 0;
        width: 100%;
        margin: 50px auto 0;
        inset-inline: 0;
        max-width: 85%;
        .lp-overlay-content {
            z-index: 2;
            width: 440px;
            background-color: rgba(255, 255, 255, 0.75);
            padding: 25px 35px 35px 35px;

            .lp-content-header {
                font-size: 24px;
                font-family: Intro, sans-serif;
                line-height: 110%;
                -webkit-font-smoothing: antialiased;
                margin-block-end: 15px;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                color: #484848;
            }

            .lp-content-body {
                font-size: 18px;
                font-family: "Regular-Book", sans-serif;
                line-height: 140%;
                -webkit-font-smoothing: antialiased;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                color: #303030;
            }

            .btn {
                margin-block-start: 20px;
                border-radius: 999px;
            }
        }

        @include mobile {
            position: relative;
            max-width: 100%;
            margin-block-start: 0;

            .lp-overlay-content {
                padding: 25px;
                width: auto;
                text-align: center;
            }
        }
    }

    .lp-overlay-img {
        img {
            height: 600px;
            display: block;
            width: 100% !important;
            object-fit: cover;
        }
    }

    .row-fluid,
    .row {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .col-md-1,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-md-5,
        .col-md-6,
        .col-md-7,
        .col-md-8,
        .col-md-9,
        .col-md-10,
        .col-md-11,
        .col-md-12 {
            flex: 0 0 auto;
            width: auto;
            min-width: 0;
            max-width: 100%;
        }

        .col-auto {
            flex: 1;
            width: auto;

            img {
                width: 100%;
            }
        }

        .col-md-1 {
            //flex: 0 0 auto;

            img {
                width: 100%;
            }

            @include mobile {
                width: 100%;
            }
        }

        .col-md-11 {
            flex: 10000 1 0%;

            @include mobile {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }

        .col-md-3 {
            max-width: 25%;
            flex: 0 0 25%;

            @include mobile {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }

        .col-md-4 {
            max-width: 33.3333%;
            flex: 0 0 33.3333%;

            @include mobile {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }

        .col-md-6 {
            max-width: 50%;
            flex: 0 0 50%;

            @include mobile {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
        
        .col-md-8 {
            max-width: 66.6666%;
            flex: 0 0 66.6666%;

            @include mobile {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }

        .col-md-9 {
            max-width: 75%;
            flex: 0 0 75%;

            @include mobile {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }

        .col-md-12 {
            width: 100%;
        }
    }

    img {
        display: block;
    }

    .btn {
        .fa-cart-shopping {
            margin-inline-end: 7px;
            font-size: 16px;
        }

        &-lg {
            font-size: 18px;
            padding: 5px 40px;
        }
    }

    .lp-testimonial-container {
        padding: 80px 0;

        @include mobile {
            padding-block-end: 40px;
        }

        .adv-img {
            img {
                max-width: 450px;
                margin: 0 auto;
            }
        }

        .row {
            align-items: center;
            justify-content: center;
        }

        .lp-testimonial-content {
            padding-left: 80px;

            .lp-testimonial-header {
                font-size: 56px;
                font-family: Intro, sans-serif;
                line-height: 110%;
                margin-block-end: 20px;
                color: #484848;
            }

            .lp-testimonial-body {
                font-size: 18px;
                font-family: "Regular-Book", sans-serif;
                color: #585858;
                line-height: 150%;
                -webkit-font-smoothing: antialiased;

                &::before,
                &::after {
                    display: inline-block;
                    vertical-align: bottom;
                    font-size: 4em;
                    inset-block-start: 0.2em;
                    position: relative;
                }

                &::before {
                    content: "\201C";
                    inset-inline-start: -4px;
                }

                &::after {
                    content: "\201D";
                    inset-inline-start: 5px;
                }
            }

            @include mobile {
                padding: 0 15px;
                text-align: center;

                .lp-testimonial-header {
                    font-size: 30px;
                    margin: 15px 0;
                }
            }
        }
    }

    li::before {
        display: none;
    }

    .lp-section {
        text-align: center;
        clear: both;

        &.black {
            background-color: #000000;

            .lp-section-text-container {
                .lp-section-text-header {
                    color: #5483ab;
                }

                .lp-section-text-body {
                    color: #ffffff;
                }
            }
        }

        &.lightgrey {
            background-color: #f9f9f9;

            .lp-section-text-container {
                .lp-section-text-header {
                    color: var(--text-red-color);
                }

                .lp-section-text-body {
                    color: #303030;
                }
            }
        }

        .lp-section-text-container {
            padding: 60px 0 70px 0;
            font-family: "Regular-Book", sans-serif;

            @include mobile {
                padding: 30px 10px;
            }

            .lp-section-text-header {
                font-family: Intro, sans-serif;
                text-transform: uppercase;
                font-size: 36px;
                margin-block-end: 12px;
                color: #303030;

                @include mobile {
                    font-size: 26px;
                }
            }

            .lp-section-text-body {
                text-transform: uppercase;
                font-size: 26px;
                color: #303030;
                
                @include mobile {
                    font-size: 18px;
                }
            }
        }
        .lp-section-block-container .col-md-12:nth-child(2) section .lp-product-slider {
            padding-top: 0;
        }
        .lp-section-block-container .col-md-12:nth-child(3) section .lp-product-slider {
            padding-top: 0;
        }
    }

    .lp-section-type- {
        &3 {
            picture {
                display: flex;
                height: 100%;
                width: 100%;

                img {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }

            .lp-product-slider {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                padding: 60px 40px;

                @include mobile {
                    padding: 40px 20px;
                }
            }

            @include mobile {
                &:nth-child(even) {
                    .lp-section-block-container {
                        .row {
                            flex-direction: column-reverse !important;
                        }
                    }
                }

                &.lightgrey {
                    background-color: #fff;
                }
            }
        }
    }

    & ~ .Breadcrumbs {
        display: none;
    }

    .lp-outfitter {
        background: #fff
            url(../../images/outfit-your-vehicle.jpg)
            no-repeat center center;
        background-size: cover;
        display: block;
        min-height: 720px;

        .header {
            font-family: regular-bold, sans-serif;
            font-size: 32px;
            text-transform: uppercase;
            color: var(--text-red-color);
            padding: 30px 50px 15px 50px;
            border-bottom: 2px rgba(255, 255, 255, 0.25) solid;
            width: auto;
            display: inline-block;

            @include mobile {
                font-size: 22px;
                padding: 30px 20px 15px 20px;
            }
        }

        .title {
            font-family: Intro, sans-serif;
            font-size: 36px;
            text-transform: uppercase;
            color: #fff;
            padding-block-start: 20px;
            letter-spacing: 1px;
            text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);

            @include mobile {
                font-size: 26px;
                padding-block-start: 15px;
            }
        }

        .finder {
            padding: 30px;
            background-color: rgba(255, 255, 255, 0.15);
            margin-block-start: 20px;
            backdrop-filter: blur(5px);
            border-radius: 10px;

            .Form {
                padding: 0px;
            }

            @include mobile {
                margin: 20px 30px 0 30px;
            }
        }

        .caption {
            color: var(--color-white);
            font-weight: 500;
            max-width: 80%;
            margin: 15px auto 0 auto;
            line-height: 1.5;
            text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
        }
    }

    .lp-blockhtml {
        .lp-blockhtml-content {
            padding: 40px 10px 30px 10px;
            text-align: center;

            h1, h2 {
                display: block;
                text-align: center;
                font-family: Intro, sans-serif !important;
                text-transform: uppercase;
                font-size: 36px;
                margin-block-end: 20px;
                padding-block-end: 0;
                color: #303030;
                line-height: 1.2;

                @include mobile {
                    font-size: 30px;
                }
            }

            p {
                font-family: Regular-Book, Helvetica, Arial, sans-serif;
                font-size: 22px;
                color: #616161;

                @include mobile {
                    font-size: 18px;
                }
            }

            ul {
                list-style: disc;
                text-align: start;
            }

            ul li {
                font-size: 16px;
                margin-block-end: 5px;
            }

            &:empty {
                display: none;
            }
        }

        .row {
            justify-content: center;
        }
    }

    .lp-designengineering {
        padding: 40px 10px;

        h2 {
            text-align: center;
            font-family: Intro, sans-serif !important;
            text-transform: uppercase;
            font-size: 36px;
            color: var(--text-blue-color);
            margin: 0 0 20px;
            line-height: 1.1;

            @include mobile {
                font-size: 30px;
            }
        }

        &-tabs-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .tab {
                box-sizing: border-box;
                border: 1px solid transparent;
                width: 200px;
                height: auto;
                min-height: 150px;
                margin: 0 10px;
                background-repeat: no-repeat;
                background-position: top 10px center;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: nowrap;
                flex-direction: column;
                text-align: center;
                color: #818282;
                font-family: Regular-Bold, sans-serif;
                font-size: 18px;
                border-radius: 0;
                cursor: pointer;
                padding: 10px;

                &-image {
                    display: block;
                    width: 120px;
                    height: 120px;
                    background-size: contain;
                }

                &-text {
                    padding: 8px 0 5px 0;
                    text-transform: uppercase;
                }

                &.react-tabs__tab--selected {
                    border: 1px solid #818282;
                    background-color: transparent;

                    &::after,
                    &::before {
                        display: inline-block;
                        top: 100%;
                        left: 50%;
                        border: solid transparent;
                        content: "";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }

                    &::after {
                        border-color: rgba(255, 255, 255, 0);
                        border-top-color: #ececec;
                        border-width: 7px;
                        margin-left: -7px;
                    }

                    &::before {
                        border-color: rgba(0, 0, 0, 0);
                        border-top-color: #818282;
                        border-width: 8px;
                        margin-left: -8px;
                        padding: 0;
                    }
                }

                @include mobile {
                    min-width: 105px;
                    width: auto;
                    height: auto;
                    min-height: 90px;
                    margin: 0;

                    &-image {
                        display: block;
                        width: 65px;
                        height: 65px;
                        background-size: contain;
                    }

                    &-text {
                        padding: 5px 0 1px 0;
                        font-size: 16px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        &-content-container {
            margin: 0 auto;
            padding: 30px 30px 0;
            max-width: 880px;
            font-family: Regular-Book, sans-serif;
            font-size: 18px;
            color: var(--text-light-black-color);
            text-align: start;
            text-align: left;

            ul {
                list-style: disc;
                margin-left: 25px;

                li {
                    color: inherit;
                }
            }
        }
    }

    .lp-landingpageslider {
        padding-block: 20px;

        a {
            display: block;
        }

        .owl-item img {
            width: 100%;
            height: auto;
        }

        &-title {
            position: absolute;
            inset-block-start: 0;
            inset-inline: 0;
            color: #fff;
            font-family: Regular-Bold, sans-serif;
            text-align: start;
            text-transform: uppercase;
            font-size: 22px;
            padding: 5px 10px 0 10px;
            z-index: 1;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
        }

        &:empty {
            padding: 1px;
        }
    }

    .VideosPopup {
        position: fixed;
        inset: 0;
        background-color: rgba(35, 35, 35, 0.7);
        z-index: 999;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &Wrapp {
            overflow: hidden;
            position: relative;
            width: 82%;
            max-width: 1320px;
            margin: 0 auto;
            max-height: 100%;

            @include mobile {
                width: 96%;
            }

            &::after {
                padding-block-start: 56.25%;
                display: block;
                content: "";
            }

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .close {
                background: url("../../images/fancybox_sprite.png");
                z-index: 999;
                right: 0;
                inset-block-start: 0;
                position: absolute;
                width: 36px;
                height: 36px;
                font-size: 0;
            }
        }
    }

    .promo_label {
        position: absolute;
        top: 5px;
        left: 5px;
        background: var(--text-red-color);
        color: #fff;
        line-height: 1;
        font-weight: 400;
        border: 1px solid var(--text-red-color);
        text-align: center;
        width: 138px;
        transform: rotateZ(-45deg);
        transform-origin: center center;
        font-family: regular-bold, sans-serif;
        text-transform: uppercase;
        user-select: none;
        font-size: 13px;
        padding: 2px 0;
        margin: 13px -45px;
        z-index: 1;

        &::after {
            content: "";
            inset-block-start: -1px;
            transform: translateX(100%);
            width: 220px;
            height: 20px;
            position: absolute;
            z-index: 1;
            animation: slide 4s infinite 3s;
            background: linear-gradient(to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(128, 186, 232, 0) 99%,
            rgba(125, 185, 232, 0) 100%);
        }
    }

    .promo_label_blue {
        background: #5188ae;
        border: 1px solid #5188ae;
    }

    .giftPromoBlock {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: center;
        gap: 15px;

        @include mobile {
            flex-direction: column;
        }

        &-Container {
            background-color: #ededed;
            border: 1px #e2e2e2 solid;
            border-bottom: 2px solid #e2e2e2;
            padding: 20px;
            width: 33%;

            @include mobile {
                padding: 15px;
                width: 100%;
            }
        }

        &-Header {
            //font-family: Regular-Bold, sans-serif;
            font-family: Intro, sans-serif;
            font-weight: 400px;
            font-size: 22px;
            color: var(--text-light-black-color);
            text-align: center;
            text-transform: uppercase;
            margin-block-end: 20px;
            min-height: 106px;
            line-height: 1.2;

            @include mobile {
                min-height: auto;
                font-size: 20px;
            }
        }

        &-Image {
            display: block;
            width: 100%;
            height: auto;
            background-color: #fff;
            position: relative;
            overflow: hidden;
        }

        &-Actions {
            display: block;
            margin-block-start: 20px;
        }

        &-Notice {
            margin-block-start: 15px;
            font-family: Regular-Bold, sans-serif;
            font-weight: 400;
            font-weight: 400px;
            font-size: 18px;
            text-transform: uppercase;

            span {
                color: var(--text-red-color);
            }
        }

        &-Tagline {
            margin-block-start: 15px;
            font-family: Regular-Book, sans-serif;
            font-weight: 400;
            font-size: 16px;
        }
    }

    // Deals page

    &--deals,
    &--deals-b2b {
        padding-block-end: 80px;

        .lp-section {
            position: relative;

            .content {
                inset-block-start: 15px;

                .fr_slider_content_top {
                    font-family: intro, sans-serif;
                    font-size: 70px;
                    color: #fff !important;

                    @include mobile {
                        font-size: 34px;
                        // color: #333 !important;
                        // text-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
                    }
                }

                .fr_slider_content_bottom {
                    font-family: regular-bold;
                    font-size: 36px;
                    color: #fff !important;
                    margin-block-start: 0;

                    @include mobile {
                        font-size: 18px;
                        // color: #333 !important;
                        // text-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
                    }
                }
            }
        }

        .lp-section {
            .lp-section-text {
                &-container {
                    padding: 50px 0 0 0;
                    text-align: start;
                    color: #555 !important;
                }

                &-header {
                    background-color: #000;
                    color: #fff !important;
                    border-radius: 3px;
                    font-size: 30px !important;
                    padding: 8px 15px;

                    @include mobile {
                        font-size: 20px !important;
                        padding: 7px 10px;
                    }
                }
            }

            &-text-container {
                position: sticky;
                top: 62px;
                z-index: 2;

                @include mobile {
                    inset-block-start: 0px;
                }
            }

            .product-slider {
                max-width: 100%;
                padding: 0;
                width: 100%;
            }

            .flippoptentdeal {
                background-color: #d6d6d6;
                width: 250px;
                height: 397px;
                padding: 15px;
                margin-inline-end: 25px;
                display: block;
                font-family: intro, sans-serif;

                &-title {
                    font-size: 15px;

                    span {
                        font-size: 20px;
                        color: var(--text-red-color);
                    }
                }

                &-image {
                    position: relative;
                    margin-block: 20px;
                    overflow: hidden;

                    a {
                        display: block;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 3px;
                    }
                }

                @include mobile {
                    display: flex;
                    flex-wrap: nowrap;
                    width: 100%;
                    margin-block-end: 15px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;

                    &-image {
                        max-width: 280px;
                    }
                }
            }
        }
    }

    .dealCountdownNew {
        display: flex;
        margin-block: 50px;

        & > div {
            width: 50%;
            padding: 20px;

            &.dealCountdownNew-countdown {
                padding-left: 0;
            }

            &.social_newsletter_cont {
                padding-block-end: 0;
            }
        }

        @include mobile {
            display: block;

            & > div {
                width: 100%;
                padding: 0;

                &.social_newsletter_cont {
                    margin-block-start: 30px;
                }
            }
        }

        .social_newsletter_cont {
            text-align: start;

            .title {
                font-size: 33px;
                text-transform: uppercase;
                font-family: Intro, sans-serif;
                color: var(--text-red-color);
                margin-block-end: 20px;
            }

            .sub_title {
                font-size: 18px;
                text-transform: uppercase;
                font-family: Intro, sans-serif;
                color: #555;
            }

            .Field {
                margin-block-start: 10px;
                width: calc(100% - 200px);

                input#email {
                    border: 2px solid #ccc;
                    width: 100%;
                    border-right: 0;
                    border-radius: 999px 0 0 999px;
                }

                @include tablet {
                    width: calc(100% - 100px);
                }

                @include mobile {
                    width: calc(100% - 80px);
                }
            }

            form.Form.EmailFriend {
                display: flex;
                align-items: end;

                button.Button {
                    line-height: 36px;
                    border-radius: 0 999px 999px 0 !important;
                }
            }

            .sub_title2 {
                font-family: "regular-bold", sans-serif;
                text-transform: uppercase;
                margin-block-start: 30px;
                font-size: 17px;
                color: #555;
            }
        }

        .countdown {
            background: #000000;
            color: #fff;
            padding: 25px 25px 45px;

            &> span {
                display: inline-block;
                margin: 0 10px;
                font-size: 16px;
                font-family: "regular-bold", sans-serif;
                text-transform: uppercase;

                @include mobile {
                    margin: 10px;
                }

                &> span {
                    display: block;
                    background: #292929;
                    font-family: Intro, sans-serif;
                    font-size: 55px;
                    line-height: 90px;
                    width: 90px;
                    height: 90px;
                    margin-block-start: 12px;
                }
            }
        }
    }
    .btnWebshopWrap {
        display: none;
        text-align: center;
    }

    #lp-content {
        ul {
            list-style: disc;
            margin-left: 25px;
            margin-bottom: 15px;
        }

        p,
        ul li {
            font-size: 16px;
            font-family: Regular-Book, Helvetica, Arial, sans-serif;
            color: #616161;
        }

        h1, h2, h3 {
            font-family: Intro, sans-serif;
            text-transform: uppercase;
            font-size: 20px;
            margin: 0 0 15px 0;
            text-align: left;
        }

        h4, h5, h6 {
            font-family: Regular-Book, Helvetica, Arial, sans-serif;
            text-transform: uppercase;
            font-size: 16px;
            margin: 0 0 15px 0;
            text-align: left;
        }

        &.content-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 40px;
            margin: 0 auto;
            // overflow: hidden;
            // max-height: 250px;
            transition: max-height 0.4s ease-in-out;
            font-size: 16px;

            @include mobile {
                grid-template-columns: 1fr;
                gap: 0;
            }

            // &.expanded {
            //     max-height: 1000px;
            //     overflow: auto;
            // }
        }

        .column {
            background-color: #fff;
            text-align: left;
        }

        .read-more-btn {
            display: block;
            text-align: center;
            margin-top: 10px;
            color: var(--text-dark-black-color);
            cursor: pointer;
            font-weight: bold;
            text-decoration: none;
        }
    }
}
