/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.lp-outfitter {
    padding: 40px 0;
    background-color: #f5f5f5;
    
    .header {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        margin-block-end: 20px;
    }
    
    .title {
        font-size: 18px;
        text-align: center;
        margin-block-end: 30px;
    }
    
    .finder {
        margin: 20px auto;
        max-width: 800px;
    }
    
    .caption {
        text-align: center;
        margin-block-start: 20px;
        font-size: 14px;
        font-style: italic;
    }
}
