/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */

.contact-wrapper {
    .contact-map {
        height: 400px;
        width: 100%;

        div {
            position: static;
        }

        .fa-3x {
            font-size: 30px;
        }
    }

    input#search_location {
        display: none;
    }

    .form-container {
        padding: 0 20px;
        padding-block: 30px 50px;

        .noError {
            .Field-Message {
                display: none;
            }
        }

        .Field_type_checkbox {
            margin-block-start: 18px;
        }

        @include mobile {
            max-width: 460px;
            margin: 0 auto;
        }

        @include desktop {
            max-width: 1170px;
            margin-inline: auto;
            padding-inline: 15px;

            .cont_form {
                display: flex;

                .cont_left {
                    width: 60%;
                    padding-inline-end: 10px;

                    @media (min-width: 992px) {
                        padding-inline-end: 15px;

                        .cnt_vehicle {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            &> .Field {
                                width: 24%;
                            }
                        }
                    }
                }

                .cont_right {
                    width: 40%;
                    padding-left: 10px;

                    @media (min-width: 992px) {
                        padding-left: 15px;
                    }
                }

                .contact_wrap {
                    padding-block-start: 32px;
                }
            }
        }

        @media (min-width: 1600px) {
            max-width: 1405px;
            width: 100%;
        }

        .cnt_top {
            font-size: 16px;
            line-height: 1.42;
            margin-block-end: 1.2em;
            color: var(--text-dark-gray-color);

            .Contact-Link {
                padding-left: 5px;
                text-transform: uppercase;
            }
        }

        h3 {
            font-family: regular-bold, sans-serif;
            font-size: 24px;
            color: var(--text-dark-gray-color);
            margin-block: 0px 25px;
            font-weight: 400;
            text-transform: uppercase;

            @include desktop {
                margin-block-end: 10px;
            }
        }

        .map_bt {
            margin-block-start: 25px;
        }

        .Field-SelectWrapper::after {
            display: none;
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        textarea,
        select {
            width: 100%;
            padding: 5px 10px;
            border: 2px solid rgba(0,0,0,.2);
            color: var(--text-gray-color);
            font-size: 14px;
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        select {
            height: 40px;
        }

        select {
            padding-inline-end: 34px;
        }

        .Field label {
            font-weight: normal;
            color: var(--text-light-black-color);
            font-size: 14px;
        }

        .FieldSelect-Select {
            pointer-events: unset;
        }

        ul.FieldSelect-Options {
            display: none;
        }

        .filepond--root.filepond--hopper {
            margin: 15px 0;
            background: #f1f1f1;
            border: 2px dashed #ccc;
            padding: 0;
            font-size: 14px;
            color: gray;
            min-height: 80px;

            .filepond--drop-label {
                color: inherit;
                min-height: 76px;
            }
        }

        button.Contact-Button {
            width: 100%;
            margin-block-start: 25px;

            @include desktop {
                width: auto;
            }
        }
    }

    .Field [type='checkbox']:checked + label, .Field [type='radio']:checked + label {
        background-color: var(--text-red-color);   
        border-color: var(--text-red-color);   
    }

    .contact_wrap {
        padding-block-start: 50px;
        font-size: 18px;
        color: var(--text-light-black-color);
        font-weight: 400;
        line-height: 1.3;
        font-family: regular-book, sans-serif;

        p {
            line-height: unset;
            color: inherit;
        }
    }
    
    .alert {
        position: relative;
        padding: 0.75rem 1.25rem;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        font-size: 16px;

        &.alert-primary {
            color: #004085;
            background-color: #cce5ff;
            border-color: #b8daff;
        }
    }
}

div#my-turnstile {
    margin-top: 15px;
}

.contactPage ~ .Breadcrumbs {
    display: none;
}

.mapMarker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);

    &:hover {
        z-index: 1;
    }
}
