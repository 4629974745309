/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable declaration-no-important */

.lp-designengineering {
    padding: 40px 10px;

    h2 {
        text-align: center;
        font-family: Intro, sans-serif !important;
        text-transform: uppercase;
        font-size: 36px;
        color: var(--text-blue-color);
        margin: 0 0 20px;
        line-height: 1.1;

        @include mobile {
            font-size: 30px;
        }
    }

    &-tabs-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .tab {
            box-sizing: border-box;
            border: 1px solid transparent;
            width: 200px;
            height: auto;
            min-height: 150px;
            margin: 0 10px;
            background-repeat: no-repeat;
            background-position: top 10px center;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            flex-direction: column;
            text-align: center;
            color: #818282;
            font-family: Regular-Bold, sans-serif;
            font-size: 18px;
            border-radius: 0;
            cursor: pointer;
            padding: 10px;

            &-image {
                display: block;
                width: 120px;
                height: 120px;
                background-size: contain;
            }

            &-text {
                padding: 8px 0 5px 0;
                text-transform: uppercase;
            }

            &.react-tabs__tab--selected {
                border: 1px solid #818282;
                background-color: transparent;

                &::after,
                &::before {
                    display: inline-block;
                    inset-inline-start: 100%;
                    inset-block-start: 50%;
                    border: solid transparent;
                    content: "";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &::after {
                    border-color: rgba(255, 255, 255, 0);
                    border-top-color: #ececec;
                    border-width: 7px;
                    margin-inline-start: -7px;
                }

                &::before {
                    border-color: rgba(0, 0, 0, 0);
                    border-top-color: #818282;
                    border-width: 8px;
                    margin-inline-start: -8px;
                    padding: 0;
                }
            }

            @include mobile {
                min-width: 105px;
                width: auto;
                height: auto;
                min-height: 90px;
                margin: 0;

                &-image {
                    display: block;
                    width: 65px;
                    height: 65px;
                    background-size: contain;
                }

                &-text {
                    padding: 5px 0 1px 0;
                    font-size: 16px;
                    text-transform: uppercase;
                }
            }
        }
    }

    &-content-container {
        margin: 0 auto;
        padding: 30px 30px 0;
        max-width: 880px;
        font-family: Regular-Book, sans-serif;
        font-size: 18px;
        color: var(--text-light-black-color);
        text-align: start;

        ul {
            list-style: disc;
            margin-inline-start: 25px;

            li {
                color: inherit;
            }
        }
    }

    // Black background variant

    .black & {
        h2 {
            color: #ffffff !important;
        }

        .lp-designengineering-tabs-container {
            .tab {
                color: #ffffff !important;
            }
        }

        .lp-designengineering-content-container {
            color: #ffffff !important;
        }
    }
}
