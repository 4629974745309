/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CmsImage {
    position: relative;
    
    img {
        max-width: 100%;
        height: auto;
    }
    
    .lp-overlay-layout {
        position: absolute;
        
        &.overlay_left {
            inset-inline-start: 50%;
            inset-block-start: 50%;
            transform: translate(-50%, -50%);
        }
        
        &.overlay_right {
            inset-inline-end: 5%;
            inset-block-start: 50%;
            transform: translateY(-50%);
        }
    }
    
    .lp-testimonial-container {
        padding: 20px;
        
        .adv-img {
            img {
                max-width: 100%;
            }
        }
    }
    
    .lp-content-header {
        font-size: 24px;
        font-weight: bold;
        margin-block-end: 10px;
    }
}
